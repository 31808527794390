/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : MediaQuery desktop (1680,1440,1366,1280)
    Author     : Rizki Nida Chaerulsyah - akuiki.net
==============================================================================================*/


/*For 1680=============================================================================================	*/

@media only screen and (min-width:0px) and (max-width: 1800px) {
    footer .wrapper {
        width: 1440px;
        height: auto;
    }
    footer .left .box {
        width: 244px;
    }
}

@media only screen and (min-width:1331px) {
    .content .content-wrapper {
        width: 985px;
    }
    .inner-content {
        width: 985px;
        padding-left: 100px;
        padding-right: 100px;
    }
}


/*For 1440=============================================================================================	*/

@media only screen and (min-width:0px) {
    .menu-content .right .wrapper {
        margin: 140px 6.5% 0;
    }
    .grid-view .box.slider-wrap .text {
        bottom: 45px;
    }
    .grid-view .box.slider-wrap .text h2 {
        font-size: 53px;
    }
    .grid-view .box a,
    .grid-view .box--column a {
        font-size: 12px;
    }
    .grid-view .box .text p,
    .grid-view .box--column .text p {
        font-size: 13px;
        line-height: 17px;
        margin: 0 0 10px;
    }
    .grid-view .box .label-ico,
    .grid-view .box--grid .label-ico {
        right: 15px;
        top: 15px;
    }
    .grid-view .box .label-ico img,
    .grid-view .box--grid .label-ico img {
        width: 35px;
    }
    .grid-view .box .text {
        padding: 20px;
    }
    .grid-view .box--grid .text-money h2 {
        font-size: 45px;
    }
    .grid-view .box--grid .text-money h5 {
        font-size: 19px;
    }
    .grid-view .box--grid .testi p {
        max-width: 75%;
        margin: auto;
    }
    .grid-view .box--grid .box--column.fund .pager-info {
        margin-left: -16px;
    }
    .grid-view .box--grid .box--column.fund h5 {
        font-size: 19px;
        margin-top: 40px;
    }
    .grid-view .box--grid .text-money h2 span {
        font-size: 12px;
    }
    .grid-view .box--grid .text-money h2 span {
        background-size: 10px;
    }
    .grid-view .box--grid .text-money span {
        font-size: 13px;
    }
    .grid-view .box--grid .box--column.fund .bx-viewport {
        max-height: 280px;
    }
    .grid-view .box .text h6 {
        font-size: 15px;
        margin: 0 0 10px 0;
        line-height: 18px;
    }
    .grid-view .box .text h4 {
        font-size: 20px;
    }
    .grid-view .box--column.news .text h3 {
        font-size: 28px;
    }
    .grid-view .box--grid .box--column.customer .text h2 {
        font-size: 30px;
    }
    .grid-view .box--grid .box--column.customer .text a.btn {
        margin: 10px 0;
    }
    .grid-view .box .social-embed h5,
    .grid-view .box--grid .social-embed h5 {
        font-size: 14px;
        margin: 0 0 15px;
    }
    .grid-view .box .social-embed .embed-area,
    .grid-view .box--grid .social-embed .embed-area {
        height: 205px;
    }
    .grid-view .box--grid .testi .bx-viewport {
        max-height: 200px;
    }
    .grid-view.news .box--column.laporan h3 {
        margin: 0 0 100px 0;
    }
    .grid-view.news .box.testi h6 {
        margin: 0 0 10px;
    }
    .grid-view.news .box.testi .img-testi {
        display: none;
    }
    .grid-view.news .box.testi p {
        font-size: 16px;
        margin: 0 0 70px;
    }
    .grid-view .box--grid .box--column.testimonial .bx-wrapper .bx-controls-direction a {
        top: 50%;
    }
    .grid-view .box--grid .box--column.fund-linked .bx-wrapper .bx-controls-direction a {
        top: 60%;
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a {
        width: 18px;
        transform: scale(0.5);
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a.bx-prev {
        left: 33%;
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a.bx-next {
        right: 33%;
    }
    .grid-view .box--grid .box--column.fund.testi .pager-info {
        bottom: 25.5%;
    }
    .ls-acc-wp .btn-acc {
        margin-left: 40px;
    }
    .floatingmenu .row .col.small {
        width: 8%;
    }
    .content .title-page h1,
    .content .title-page h6 {
        font-size: 16px;
    }
    .content .title-page .ico {
        width: 70px;
        height: 70px;
    }
    .inner-content {
        width: 985px;
    }
    .inner-content h2 {
        font-size: 50px;
        margin: 0 0 15px;
        line-height: 55px;
    }
    .inner-content h4 {
        font-size: 28px;
        line-height: 34px;
    }
    .inner-content h4:after {
        margin: 40px 0 20px;
    }
    .content .title-page .nav a {
        width: 70px;
        height: 70px;
        background-size: 140px auto !important;
    }
    .content .title-page .nav {
        width: 70px;
        top: 70px;
    }
    .grid-view.news .box--column.kontak-media a.mail {
        padding: 0 0 35px 0;
    }
    .grid-view.news .box--column.kontak-media h6 {
        font-size: 24px;
        margin: 15px 0 5px;
    }
    .grid-view.news .box--column.kontak-media h3 {
        font-size: 40px;
        line-height: 35px;
    }
    footer .wrapper {
        width: auto;
        float: right;
        margin-left: 50px;
    }
    footer .left .box {
        width: 203px;
    }
    .grid-view .box--grid .testi p {
        font-size: 14px;
        height: 42px;
    }
    .ls-acc-wp .btn-acc {
        margin: 12px 0 12px 40px;
        font-size: 17px;
    }
    .floatingmenu {
        height: 112px;
    }
    .floatingmenu .row label {
        font-size: 14px;
    }
    .floatingmenu .row input[type='text'],
    .floatingmenu .row input[type='file'],
    .floatingmenu .row select {
        height: 36px;
        padding: 8px 10px;
        font-size: 15px;
    }
    .floatingmenu .row input[type='submit'] {
        height: 36px;
        width: auto;
        font-size: 15px;
        margin-top: 25px;
    }
}


/*For 1366=============================================================================================	*/

@media only screen and (min-width:0px) and (max-width: 1419px) {
    .grid-view .box--grid .text-money h2 {
        font-size: 36px;
    }
    .grid-view .box--grid .box--column.fund .text-money h2 {
        margin: 30px 0 15px;
    }
    .content .title-page .ico {
        margin-right: 30px;
    }
    .inner-content {
        margin-right: 2%;
    }
    .grid-view.news .box.testi p {
        font-size: 14px;
        margin: 0 0 70px;
        height: 63px;
        overflow: hidden;
    }
    .grid-view .box--grid .testi .bx-viewport {
        max-height: 200px;
        min-height: 170px;
    }
    .grid-view .box--grid .testi h3 {
        margin: 35px 0 20px 0;
    }
    .grid-view .box--grid .testi h6 {
        margin: 0 0 10px;
    }
    .inner-content h2 {
        font-size: 45px;
        line-height: 50px;
    }
    .inner-content .qoutes {
        font-size: 24px;
    }
    .inner-content .qoutes:before {
        height: 45px;
    }
    .inner-content p {
        font-size: 16px;
    }
    .tittle-slider h3 {
        font-size: 22px;
        line-height: 26px;
    }
    .content .form-message select {
        z-index: 1;
    }
    .box-rekomendasi h3 {
        font-size: 20px;
    }
    .wrap-popup .box-popup.about p,
    .wrap-popup .box-popup.about ul li,
    .inner-content table,
    .inner-content ul li,
    .inner-content ol li,
    .inner-content p {
        font-size: 16px;
        line-height: 24px;
    }
    .btn-readmore {
        font-size: 16px;
    }
    .floatingmenu .row .col {
        width: 19.8%;
    }
    .floatingmenu .row .col.small {
        width: 8%;
    }
    .floatingmenu input[type='submit'] {
        margin-top: 26px;
        font-size: 15px;
    }
    footer .left .box {
        width: 240px;
    }
    footer .right {
        width: 195px;
    }
    footer .right .social {
        margin: 5px 0 35px 0;
    }
    footer .right img:nth-child(4) {
        width: 110px
    }
    footer .right img:nth-child(5) {
        width: 50px
    }
}

@media screen and (min-width: 961px)and (orientation: portrait) {
    .floatingmenu {
        overflow-y: hidden;
    }
}

@media screen and (min-width: 961px) {
    .header .header .floatingmenu {
        display: none;
    }
    .floatingmenu {
        display: none;
        right: -100%;
    }
    .floatingmenu.active {
        display: block;
    }
    .floatingmenu .row {
        flex-direction: row;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ls-acc-wp .btn-acc {
        display: none;
    }
}

@media screen and (min-width: 1281px) {
    .grid-view.news .box.slider-wrap .text h2 {
        font-size: 36px;
        line-height: 1em;
    }
    .grid-view .box.slider-wrap .text p {
        font-size: 18px;
        margin-bottom: 24px;
    }
    .grid-view .slider .text .text-wrapper button {
        font-size: 18px;
    }
}

@media screen and (min-width: 961px) and (max-width: 1280px) {
    .grid-view.news .box.slider-wrap .text h2 {
        font-size: 28px;
        line-height: 1em;
        margin-bottom: 20px;
    }
    .grid-view .box.slider-wrap .text p {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .grid-view .slider .text .text-wrapper button {
        font-size: 16px;
    }
}

@media screen and (min-device-width: 480px) and (max-device-width: 960px) and (orientation: landscape) {
    .accordion-wp .floatingmenu.active {
        height: 100%;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 960px) {
    .grid-view.news .box.slider-wrap .text h2 {
        font-size: 28px;
        line-height: 1.2em;
        margin-bottom: 15px;
    }
    .grid-view .box.slider-wrap .text p {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .solution {
        display: none;
    }
    .floatingmenu {
        height: 40px;
        z-index: 5;
        right: 30px;
    }
    .floatingmenu form {
        height: 100%;
        position: relative;
        overflow: hidden;
    }
    .floatingmenu .row {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ls-acc-wp .btn-acc {
        display: block;
        font-size: 14px;
        line-height: 30px;
        color: #fff;
        padding-left: 20px;
        box-sizing: border-box;
        font-weight: bold;
        margin: 5px 0;
        position: relative;
    }
    .ls-acc-wp .btn-acc:after {
        position: absolute;
        display: block;
        content: "";
        background: url(../images/material/arr-down.png) no-repeat;
        height: 15px;
        width: 15px;
        right: 20px;
        top: 8px;
        transform: unset;
    }
    .ls-acc-wp.active .btn-acc:after {
        transform: rotate(180deg);
    }
    .floatingmenu .form {
        overflow: auto;
        height: 370px;
    }
    .floatingmenu .row .col:first-child {
        margin: 0;
        margin-bottom: 20px;
    }
    .floatingmenu .row .col,
    .floatingmenu .row .col.small {
        width: 100%;
        margin: 0;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .floatingmenu .row .col.sub {
        width: 100%;
        text-align: center;
    }
    .floatingmenu input[type='submit'] {
        width: 190px;
    }
    .floatingmenu.active {
        height: auto;
        padding-bottom: 30px;
        -webkit-transition: padding 1s ease-out;
        -moz-transition: padding 1s ease-out;
        -o-transition: padding 1s ease-out;
        transition: padding 1s ease-out;
    }
    .floatingmenu.active:before {
        display: none;
    }
    .drop-acc {
        -webkit-transition: padding 2s ease-out;
        -moz-transition: padding 2s ease-out;
        -o-transition: padding 2s ease-out;
        transition: padding 2s ease-out;
    }
}